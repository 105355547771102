.react-tabs__tab {
  border-radius: 20px;
  border: 1px solid var(--primary-color);
  margin: 0 5px;
  font-weight: bold;
  color: var(--primary-color);
  &.react-tabs__tab--selected {
    background-color: var(--primary-color);
    color: #fff;
  }
}

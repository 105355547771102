@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Fredoka";
  src: url("./fonts/Fredoka/static/Fredoka-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-Regular.ttf");
}

body {
  font-family: "Inter", sans-serif;
}

:root {
  --primary-color: rgb(96 165 250);
  --secondary-color: rgb(55 130 246);
}
